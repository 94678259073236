<template>
  <div>
    <TeTopCoverConsultation
      :paid="paid"
      :applied-htmlcss-trial="appliedHtmlcssTrial"
    />

    <TeCommonBannerMypage v-show="!paid && appliedHtmlcssTrial" />

    <TeCommonReskillingBanner />

    <TeCommonBannerLinksTitle />

    <TeCommonBannerTimeLimitCpnCode
      v-if="BannerNameForShowing === 'campaign'"
      :entry-campaign="entryCampaign"
    />

    <TeCommonBannerSettlement
      v-if="BannerNameForShowing === 'settlement'"
      :entry-settlement-time-limit="entrySettlementTimeLimit"
      :entry-payment-url="entryPaymentUrl"
    />
    <TeCommonBannerTimelimit
      v-if="BannerNameForShowing === 'discount'"
      :entry-discount-time-limit="entryDiscountTimeLimit"
    />
    <TeTopRecommend />

    <!-- CEOクローン -->
    <section
      id="ceo-video"
      class="co-bg-striped py-[3.25rem]"
    >
      <div class="co-container-sm px-4 md:px-20 lg:px-0">
        <div class="co-display-8 mb-8 text-center font-bold">
          ＼ もっと詳しく知りたい方には ／
        </div>
        <div
          class="flex flex-col gap-9 lg:flex-row-reverse"
        >
          <div>
            <h2 class="mb-5 flex items-center justify-center">
              <FontAwesomeIcon
                :icon="faCirclePlay"
                class="fa-fw co-display-3 mr-4 hidden size-11 text-co-primary-400 md:block"
              />
              <div class="text-center md:text-left">
                <span class="co-display-10 block font-bold leading-snug tracking-normal">
                  気になるところだけ選んで見られる
                </span>
                <span class="co-display-6 font-bold leading-snug tracking-normal">
                  動画deテックアカデミー
                </span>
              </div>
            </h2>
            <p class="mb-6 text-co-gray-600">
              どんなことが学べるのか、サポート体制、副業・転職について、受講料・補助金について、などなど・・・皆さんの気になることにズバリお答えするサービス紹介動画です。ただの動画ではなく気になる内容だけを選んで見ることができる優れものです。ぜひご利用ください。
            </p>
            <div class="text-center lg:text-left">
              <a
                class="co-btn-secondary"
                href="https://ceoclone.com/kiramex-techacademy/01J32CPH7GBWECJT0HM2NJCXDF"
                target="_blank"
              >
                動画deテックアカデミーを見る
                <FontAwesomeIcon
                  :icon="faAngleRight"
                  class="fa-fw inline h-4 w-2"
                />
              </a>
            </div>
          </div>
          <img
            class="mx-auto"
            src="~/assets/images/Te/TeTop/Ceo/ceo-image.webp"
            width="420"
            height="331"
            alt="皆様の疑問にお答えします"
          >
        </div>
      </div>
    </section>

    <TeTopCtaSecond />

    <TeTopWork />

    <TeCommonAboutTechAcademy />

    <TeCommonComparisonSchoolSidejob
      :benefits="true"
      :mentoring="true"
    />

    <!-- 受講生の声 -->
    <div
      id="voice"
      class="co-bg-striped py-12 sm:py-20"
    >
      <div class="co-container-lg px-4">
        <h2 class="co-heading-2">
          受講生の声
        </h2>
        <h3 class="mb-8 text-center text-[1.25rem] font-bold md:mb-[3.25rem] md:text-[1.75rem]">
          <FontAwesomeIcon
            :icon="faXTwitter"
            class="fa-fw mr-3 md:mr-4"
          />
          活躍中の受講生の口コミ
        </h3>

        <TeTopReviewsTwitter />

        <h3 class="mb-8 text-center text-[1.25rem] font-bold md:mb-[3.25rem] md:text-[1.75rem]">
          <FontAwesomeIcon
            :icon="faMicrophone"
            class="fa-fw mr-3 inline h-7 w-4"
          />
          受講生インタビュー
        </h3>
        <TeTopInterview />
      </div>
    </div>

    <!-- バナーWeb制作の仕事 -->
    <TeCommonBannerOccupationTest />

    <!-- コース診断 -->
    <TeCommonBannerDiagnoses />

    <TeCommonBannerBriefingHtmlcssTrial :schedule-apply="true" />

    <!-- 下部固定ボタン -->
    <TeCommonButtonFixedBottom />
  </div>
</template>

<script lang="ts">
import { useUser } from '~/store/user'
import { IndexServices } from '~/services/indexService'
import { scriptTagsByConfig } from '~/utility/scriptTags'
import { metaTags } from '~/utility/constants/metaTags'

import TeTopCoverConsultation from '~/components/Te/TeTop/TeTopCoverConsultation.vue'
import TeCommonBannerMypage from '~/components/Te/TeCommon/Banner/TeCommonBannerMypage/TeCommonBannerMypage.vue'
import TeCommonBannerLinksTitle from '~/components/Te/TeCommon/Banner/TeCommonBannerLinksTitle/TeCommonBannerLinksTitle.vue'
import TeCommonReskillingBanner from '~/components/Te/TeCommon/TeCommonReskillingBanner.vue'
import TeCommonBannerTimeLimitCpnCode from '~/components/Te/TeCommon/Banner/TeCommonBannerTimeLimitCpnCode/TeCommonBannerTimeLimitCpnCode.vue'
import TeCommonBannerSettlement from '~/components/Te/TeCommon/Banner/TeCommonBannerSettlement/TeCommonBannerSettlement.vue'
import TeCommonBannerTimelimit from '~/components/Te/TeCommon/Banner/TeCommonBannerTimelimit/TeCommonBannerTimelimit.vue'
import TeTopRecommend from '~/components/Te/TeTop/TeTopRecommend.vue'
import TeTopCtaSecond from '~/components/Te/TeTop/TeTopCtaSecond.vue'
import TeTopWork from '~/components/Te/TeTop/TeTopWork.vue'
import TeCommonAboutTechAcademy from '~/components/Te/TeCommon/TeCommonAboutTechAcademy/TeCommonAboutTechAcademy.vue'
import TeCommonComparisonSchoolSidejob from '~/components/Te/TeCommon/Comparison/TeCommonComparisonSchoolSidejob/TeCommonComparisonSchoolSidejob.vue'
import TeTopReviewsTwitter from '~/components/Te/TeTop/TeTopReviewsTwitter.vue'
import TeTopInterview from '~/components/Te/TeTop/TeTopInterview.vue'
import TeCommonBannerOccupationTest from '~/components/Te/TeCommon/Banner/TeCommonBannerOccupationTest/TeCommonBannerOccupationTest.vue'
import TeCommonBannerDiagnoses from '~/components/Te/TeCommon/Banner/TeCommonBannerDiagnoses/TeCommonBannerDiagnoses.vue'
import TeCommonBannerBriefingHtmlcssTrial from '~/components/Te/TeCommon/Banner/TeCommonBannerBriefingHtmlcssTrial/TeCommonBannerBriefingHtmlcssTrial.vue'
import TeCommonButtonFixedBottom from '~/components/Te/TeCommon/Button/TeCommonButtonFixedBottom/TeCommonButtonFixedBottom.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faXTwitter } from '@awesome.me/kit-65d95819ce/icons/classic/brands'
import { faCirclePlay } from '@awesome.me/kit-65d95819ce/icons/classic/regular'
import { faAngleRight, faMicrophone } from '@awesome.me/kit-65d95819ce/icons/classic/solid'

const options = {
  async setup() {
    const userStore = useUser()
    const { currentUser, appliedHtmlcssTrial, paid } = storeToRefs(userStore)

    return {
      userStore,
      currentUser,
      appliedHtmlcssTrial,
      paid,
      faXTwitter,
      faCirclePlay,
      faAngleRight,
      faMicrophone,
    }
  },
  oldHead() {
    const scriptTags = scriptTagsByConfig({
      useCase: 'index',
      deviceSize: useNuxtApp().$deviceSize,
    })
    return {
      title: 'プログラミングスクール | 受講者数No.1のテックアカデミー',
      meta: [
        ...metaTags(
          {
            description:
              '【テックアカデミー(TechAcademy)】受講者数No.1のオンラインプログラミングスクール。通過率10%を突破した現役エンジニアからマンツーマンで学べる。転職や副業に活かせるスキルを最短4週間で習得。',
            ogUrl: '/',
          },
          process.env.host as string
        ),
      ],
      script: scriptTags!.script,
      noscript: scriptTags!.noscript,
    }
  },
  data() {
    return {
      entryDiscountTimeLimit: null,
      entrySettlementTimeLimit: null,
      entryPaymentUrl: null,
      entryCampaign: null,
    }
  },
  computed: {
    BannerNameForShowing() {
      if (this.entrySettlementTimeLimit) {
        return 'settlement'
      } else if (this.entryCampaign) {
        return 'campaign'
      } else if (this.entryDiscountTimeLimit) {
        return 'discount'
      } else {
        return null
      }
    },
  },
  async mounted() {
    const {
      data: {
        entryDiscountTimeLimit,
        entrySettlementTimeLimit,
        entryPaymentUrl,
        entryCampaign,
      },
    } = await new IndexServices(this.$api).getTopTimeLimits()
    this.entryDiscountTimeLimit = entryDiscountTimeLimit
    this.entrySettlementTimeLimit = entrySettlementTimeLimit
    this.entryPaymentUrl = entryPaymentUrl
    this.entryCampaign = entryCampaign

    await this.userStore.getCurrentUser()
  },
  methods: {},
  components: {
    TeTopCoverConsultation,
    TeCommonBannerMypage,
    TeCommonBannerLinksTitle,
    TeCommonReskillingBanner,
    TeCommonBannerTimeLimitCpnCode,
    TeCommonBannerSettlement,
    TeCommonBannerTimelimit,
    TeTopRecommend,
    TeTopCtaSecond,
    TeTopWork,
    TeCommonAboutTechAcademy,
    TeCommonComparisonSchoolSidejob,
    TeTopReviewsTwitter,
    TeTopInterview,
    TeCommonBannerOccupationTest,
    TeCommonBannerDiagnoses,
    TeCommonBannerBriefingHtmlcssTrial,
    TeCommonButtonFixedBottom,
    FontAwesomeIcon,
  },
}

export default defineNuxtComponent(options)
</script>
